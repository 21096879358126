import { SCHEMES } from "../use-color-scheme";

const themeWidths = {
  narrow: "520px",
  medium: "864px",
  wide: "1156px",
  full: "98%",
} as const;

export const defaultThemeStyles = {
  fontFamily: '"Inter", sans-serif',
  pageWidth: 520,
  width: themeWidths,
};

// These colors can be customized by users
export const defaultCustomizableThemeColors = {
  pageBackground: "#F3F6FC",
  primary: "#005AED",
  lightPrimary: "rgb(231, 239, 253)",
  danger: "#D62828",
  lightDanger: "rgb(251, 233, 233)",
  formError: "#D62828",
  text: "#152542",
  secondaryText: "#3E547C",
  white: "#ffffff",
  black: "#000000",
  gray: "#bbc4d4",
  darkGray: "#3E547C",
  mediumGray: "#a3afc4",
  lightGray: "#E2E7F0",
  superLightGray: "#FCFDFE",
  placeholder: "#8a91a0",
} as const;

export type CustomizableThemeColorName =
  keyof typeof defaultCustomizableThemeColors;

export const customizableThemeColorNames = Object.keys(
  defaultCustomizableThemeColors
) as CustomizableThemeColorName[];

const themeColors = {
  ...defaultCustomizableThemeColors,
  // These colors are not customizable by users. They can be hardcoded or
  // calculated from other colors.
  themeBackground: "#F3F6FC",
};

type ThemeColors = typeof themeColors;
export type ColorName = keyof ThemeColors;

export default function getTheme(scheme: SCHEMES) {
  return {
    ...defaultThemeStyles,
    scheme: {
      value: scheme,
      isLight: scheme === SCHEMES.LIGHT,
      isDark: scheme === SCHEMES.DARK,
    },
    colors: themeColors,
  };
}
