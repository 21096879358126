import Head from "next/head";
import { useRouter } from "next/router";

export function GlobalHead() {
  const router = useRouter();
  return (
    <Head>
      {!router.pathname.startsWith("/public/templates") && (
        <link
          rel="icon"
          href="/icon-interfaces.svg"
          type="image/svg+xml"
          key="favicon"
        />
      )}
    </Head>
  );
}
