import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import axios from "axios";
import { getFriendlyErrorMessageWithErrorCode } from "lib/getFriendlyErrorMessage";
import { relogin, shouldRelogin } from "lib/utils/auth";
import { isFailedToFetchError } from "lib/utils/isFailedToFetchError";
import Error from "next/error";
import { ReactNode } from "react";

import * as Sentry from "@sentry/nextjs";

export function PageErrorBoundary(props: { children?: ReactNode }) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({ error }) => {
            if (isFailedToFetchError(error)) {
              console.error("failed to fetch, resetting error boundary", error);
              reset();
            }

            if (shouldRelogin(error)) {
              relogin();
              return <></>;
            }

            let status = 500;

            if (axios.isAxiosError(error) && error.response) {
              status = error.response.status;
            }
            if (error instanceof TRPCClientError) {
              if (typeof error.data?.httpStatus === "number") {
                status = error.data.httpStatus;
              }
            }

            return (
              <Error
                statusCode={status}
                // Trim trailing period because the Vercel component adds one.
                title={getFriendlyErrorMessageWithErrorCode(error).replace(
                  /\.$/,
                  ""
                )}
              />
            );
          }}
        >
          {props.children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
