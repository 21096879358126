import {
  Modal,
  ModalContent,
  ModalContentBody,
  ModalContentHeader,
  Spacer,
  Text,
  ButtonGroup,
  Button,
} from "@zapier/design-system";
import React, { createContext, useContext, useState } from "react";

export interface ConfirmModalProps {
  title?: string;
  text: string;
  onOk: (value?: unknown) => void;
  onCancel: () => void;
}

interface ConfirmContextValue {
  isOpen: boolean;
  props: ConfirmModalProps;
}

type SetConfirmContextValue = React.Dispatch<
  React.SetStateAction<ConfirmContextValue>
>;

const defaultValue: ConfirmContextValue = {
  isOpen: false,
  props: {
    title: "",
    text: "",
    onOk: () => {
      return null;
    },
    onCancel: () => {
      return null;
    },
  },
};

export const ConfirmModalContext = createContext<
  [ConfirmContextValue, SetConfirmContextValue]
>([
  defaultValue,
  () => {
    return null;
  },
]);

// Actual modal shown
export function ModalConfirmation(props: {
  title?: string;
  text: string;
  onOk: () => void;
  onCancel: () => void;
}) {
  const { title, text: content, onOk, onCancel } = props;

  return (
    <Modal onClosed={onCancel}>
      <ModalContent maxWidth="60ch">
        {title ? (
          <ModalContentHeader>
            <Text type="subHeader3Bold">{title}</Text>
          </ModalContentHeader>
        ) : null}

        <Spacer height="0px" />

        <ModalContentBody>
          <Text tag="p" type="paragraph3" color="neutral700">
            {content}
          </Text>
        </ModalContentBody>

        <Spacer height="30px" />

        <ButtonGroup maxWidth="300px">
          <Button color="primary" onClick={() => onOk()} tabIndex={-1}>
            Yes
          </Button>
          <Button color="tertiary" onClick={() => onCancel()} tabIndex={-1}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
}

export const ConfirmModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirmProps, setConfirmProps] =
    useState<ConfirmContextValue>(defaultValue);

  return (
    <ConfirmModalContext.Provider value={[confirmProps, setConfirmProps]}>
      {children}
      {confirmProps.isOpen ? (
        <ModalConfirmation {...confirmProps.props} />
      ) : null}
    </ConfirmModalContext.Provider>
  );
};

export function useConfirmModalContext() {
  return React.useContext(ConfirmModalContext);
}

export const useConfirmModal = () => {
  const [, setConfirmProps] = useContext(ConfirmModalContext);

  const showConfirmModal = (props: { title?: string; text: string }) => {
    const promise = new Promise((resolve, reject) => {
      setConfirmProps({
        isOpen: true,
        props: {
          title: props.title,
          text: props.text,
          onOk: resolve,
          onCancel: reject,
        },
      });
    });
    return promise.then(
      () => {
        setConfirmProps((confirmProps) => ({ ...confirmProps, isOpen: false }));
        return true;
      },
      () => {
        setConfirmProps((confirmProps) => ({ ...confirmProps, isOpen: false }));
        return false;
      }
    );
  };

  const closeConfirmModal = () => {
    setConfirmProps((confirmProps) => ({ ...confirmProps, isOpen: false }));
  };

  return { showConfirmModal, closeConfirmModal };
};
