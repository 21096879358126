import "zod-openapi/extend";

import { z } from "zod";

const RemSchema = z.string().regex(/^\d+(?:\.\d+)?rem$/i);

const HexSchema = z
  .string()
  .regex(/^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i);

export const InterfacesThemeSchema = z
  .object({
    darkModeEnabled: z.boolean().default(false),

    brandColor: HexSchema.default("#2563EB"),
    radius: RemSchema.default("0.5rem"),

    background: HexSchema.default("#FFFFFF"),
    foreground: HexSchema.default("#020817"),
    card: HexSchema.default("#FFFFFF"),
    cardForeground: HexSchema.default("#020817"),
    popover: HexSchema.default("#FFFFFF"),
    popoverForeground: HexSchema.default("#020817"),
    primary: HexSchema.default("#2563EB"),
    primaryForeground: HexSchema.default("#F8FAFC"),
    secondary: HexSchema.default("#F1F5F9"),
    secondaryForeground: HexSchema.default("#0F172A"),
    muted: HexSchema.default("#F1F5F9"),
    mutedForeground: HexSchema.default("#64748B"),
    accent: HexSchema.default("#F1F5F9"),
    accentForeground: HexSchema.default("#0F172A"),
    destructive: HexSchema.default("#EF4444"),
    destructiveForeground: HexSchema.default("#F8FAFC"),
    border: HexSchema.default("#E2E8F0"),
    input: HexSchema.default("#E2E8F0"),
    ring: HexSchema.default("#2563EB"),
    inputField: HexSchema.default("#FFFFFF"),
    inputFieldForeground: HexSchema.default("#020817"),

    darkBackground: HexSchema.default("#020817"),
    darkForeground: HexSchema.default("#F8FAFC"),
    darkCard: HexSchema.default("#020817"),
    darkCardForeground: HexSchema.default("#F8FAFC"),
    darkPopover: HexSchema.default("#020817"),
    darkPopoverForeground: HexSchema.default("#F8FAFC"),
    darkPrimary: HexSchema.default("#3B82F6"),
    darkPrimaryForeground: HexSchema.default("#0F172A"),
    darkSecondary: HexSchema.default("#1E293B"),
    darkSecondaryForeground: HexSchema.default("#F8FAFC"),
    darkMuted: HexSchema.default("#1E293B"),
    darkMutedForeground: HexSchema.default("#94A3B8"),
    darkAccent: HexSchema.default("#1E293B"),
    darkAccentForeground: HexSchema.default("#F8FAFC"),
    darkDestructive: HexSchema.default("#7F1D1D"),
    darkDestructiveForeground: HexSchema.default("#F8FAFC"),
    darkBorder: HexSchema.default("#1E293B"),
    darkInput: HexSchema.default("#1E293B"),
    darkRing: HexSchema.default("#94A3B8"),
    darkInputField: HexSchema.default("#020817"),
    darkInputFieldForeground: HexSchema.default("#F8FAFC"),
  })
  .openapi({ ref: "InterfacesTheme" });

export type InterfacesTheme = z.infer<typeof InterfacesThemeSchema>;

const HslSchema = z
  .string()
  .regex(/^\d+(?:\.\d+)? \d+(?:\.\d+)?% \d+(?:\.\d+)?%$/i);

export const HslThemeSchema = z.object({
  brandColor: HexSchema,
  radius: RemSchema,

  background: HslSchema,
  foreground: HslSchema,
  card: HslSchema,
  cardForeground: HslSchema,
  popover: HslSchema,
  popoverForeground: HslSchema,
  primary: HslSchema,
  primaryForeground: HslSchema,
  secondary: HslSchema,
  secondaryForeground: HslSchema,
  muted: HslSchema,
  mutedForeground: HslSchema,
  accent: HslSchema,
  accentForeground: HslSchema,
  destructive: HslSchema,
  destructiveForeground: HslSchema,
  border: HslSchema,
  input: HslSchema,
  ring: HslSchema,
  inputField: HslSchema,
  inputFieldForeground: HexSchema,

  darkBackground: HslSchema,
  darkForeground: HslSchema,
  darkCard: HslSchema,
  darkCardForeground: HslSchema,
  darkPopover: HslSchema,
  darkPopoverForeground: HslSchema,
  darkPrimary: HslSchema,
  darkPrimaryForeground: HslSchema,
  darkSecondary: HslSchema,
  darkSecondaryForeground: HslSchema,
  darkMuted: HslSchema,
  darkMutedForeground: HslSchema,
  darkAccent: HslSchema,
  darkAccentForeground: HslSchema,
  darkDestructive: HslSchema,
  darkDestructiveForeground: HslSchema,
  darkBorder: HslSchema,
  darkInput: HslSchema,
  darkRing: HslSchema,
  darkInputField: HslSchema,
  darkInputFieldForeground: HexSchema,
});

export type HslTheme = z.infer<typeof HslThemeSchema>;
