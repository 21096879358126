import {
  Alert,
  AlertDescription,
  AlertType,
  ColorsCSS as Colors,
} from "@zapier/design-system";
import { useEffect, useState } from "react";
import toast, {
  resolveValue,
  Toaster as ReactHotToaster,
  ToastType,
} from "react-hot-toast";
import Markdown, { Options } from "react-markdown";
import remarkGfm from "remark-gfm";
import { cn } from "utils/cn";
import { styled } from "./theme";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

const Wrapper = styled.div`
  width: 640px;
  max-width: 100%;

  transition: opacity 0.15s;

  & [class*="AlertDescription__content"] > [class*="AlertDescription"] {
    white-space: pre-wrap !important;
  }
`;

const StyledCode = styled.code`
  color: ${Colors.neutral500};
  font-weight: 100;
  font-size: 12px;
  line-height: 24px;
  vertical-align: middle;
`;

export function Toaster() {
  const interfacesTheme = useInterfacesTheme();

  const [show, setShow] = useState(false);

  const components: Options["components"] = {
    a: (attrs) => (
      <a
        {...attrs}
        target="_blank"
        rel="noreferrer noopener"
        className={cn(
          "text-inherit no-underline focus-visible:outline-none",
          interfacesTheme
            ? "rounded-small focus-visible:shadow-[0_0_0_2px_hsl(var(--background)),_0_0_0_4px_hsl(var(--primary))]"
            : "rounded-[2px] focus-visible:shadow-[0_0_0_2px_hsl(var(--zi-pageBackground)),_0_0_0_4px_hsl(var(--zi-primary))]",
          attrs.className
        )}
      />
    ),
    code: (attrs) => <StyledCode {...attrs} />,
  };

  useEffect(() => {
    setShow(true);
  }, []);

  if (!show) return null;

  return (
    <ReactHotToaster>
      {(t) => {
        return (
          <Wrapper
            style={{
              opacity: t.visible ? 1 : 0,
            }}
            data-testid="toast"
          >
            <Alert
              ariaLive={t.ariaProps["aria-live"]}
              // There is a small mismatch between attributes.
              // @ts-ignore
              role={t.ariaProps.role}
              type={translateAlertType(t.type)}
              onDismiss={() => toast.dismiss(t.id)}
            >
              <AlertDescription>
                <Markdown remarkPlugins={[remarkGfm]} components={components}>
                  {String(resolveValue(t.message, t))}
                </Markdown>
              </AlertDescription>
            </Alert>
          </Wrapper>
        );
      }}
    </ReactHotToaster>
  );
}

function translateAlertType(toastType: ToastType): AlertType {
  switch (toastType) {
    case "blank":
    case "loading":
    case "custom":
      return "info";

    case "success":
      return "success";

    case "error":
      return "error";

    default:
      const _exhaustiveCheck: never = toastType;
      return _exhaustiveCheck;
  }
}
