import { SCHEMES } from "../use-color-scheme";
import getBuilderTheme from "./builder-theme";
import getAppTheme from "./app-theme";

export default function getTheme(scheme: SCHEMES) {
  return {
    app: getAppTheme(scheme),
    builder: getBuilderTheme(scheme),
  };
}

export type Theme = ReturnType<typeof getTheme>;
