export function compileColors<T extends { [key: string]: string | string[] }>(
  themeColors: T
) {
  type ColorKeys = keyof T;

  type Colors = {
    [Property in ColorKeys]: string;
  };

  const lightColors: Partial<Colors> = {};
  const darkColors: Partial<Colors> = {};

  let key: keyof T;
  for (key in themeColors) {
    const colors = themeColors[key];

    lightColors[key] = colors[0];
    darkColors[key] = colors?.[1] ?? colors[0];
  }

  const returnValue = [lightColors as Colors, darkColors as Colors] as [
    Colors,
    Colors
  ];

  return returnValue;
}
