import { SCHEMES } from "../use-color-scheme";
import { compileColors } from "./utils";

const themeStyles = {};

const themeColors = {};

type ThemeColors = typeof themeColors;

const [lightColors, darkColors] = compileColors<ThemeColors>(themeColors);

export default function getTheme(scheme: SCHEMES) {
  return {
    ...themeStyles,
    scheme: {
      value: scheme,
      isLight: scheme === SCHEMES.LIGHT,
      isDark: scheme === SCHEMES.DARK,
    },
    colors: scheme === SCHEMES.LIGHT ? lightColors : darkColors,
  };
}
