import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider as ReactDndProvider } from "react-dnd";
import { ReactNode } from "react";
import { BlockId, ContentBlockTypes } from "block-system/types";

export function DndProvider(props: { children: ReactNode }) {
  return (
    <ReactDndProvider backend={HTML5Backend}>{props.children}</ReactDndProvider>
  );
}

export enum DragTypes {
  CONTENT_BLOCK = "CONTENT_BLOCK",
  NEW_CONTENT_BLOCK_TYPE = "NEW_CONTENT_BLOCK_TYPE",
}

export type NewContentBlockDragItem = {
  blockType: ContentBlockTypes;
};

export type BlockDropResult = void;

export type ContentBlockDragItem = {
  blockId: BlockId;
  offsetHight: number | undefined;
  index: number;
};

export type ContentBlockDropResult = void;
