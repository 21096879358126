// // Adapted from https://github.com/zebateira/react-use-system-theme

// import {
//   useState,
//   useEffect,
//   useLayoutEffect as ReactUseLayoutEffect,
// } from "react";

export enum SCHEMES {
  DARK = "dark",
  LIGHT = "light",
}

// const useLayoutEffect =
//   typeof window !== "undefined" ? ReactUseLayoutEffect : useEffect;

// export const colorSchemes = {
//   light: "(prefers-color-scheme: light)",
//   dark: "(prefers-color-scheme: dark)",
// };

// function onThemeChange(callback: () => void) {
//   return (event: MediaQueryListEvent) => {
//     if (!event || !event.matches) {
//       return;
//     }

//     callback();
//   };
// }

// export default function useSystemColorScheme(
//   initialTheme: SCHEMES = SCHEMES.LIGHT
// ): SCHEMES {
//   const [theme, setTheme] = useState(initialTheme);

//   useEffect(() => {
//     // SSR or matchMedia not supported
//     if (typeof window === "undefined" || !window.matchMedia) {
//       return undefined;
//     }

//     const lightMatch = window.matchMedia(colorSchemes.light);
//     const onLightMatches = onThemeChange(() => {
//       setTheme(SCHEMES.LIGHT);
//     });

//     lightMatch.addEventListener("change", onLightMatches);

//     const darkMatch = window.matchMedia(colorSchemes.dark);
//     const onDarkMatches = onThemeChange(() => {
//       setTheme(SCHEMES.DARK);
//     });

//     darkMatch.addEventListener("change", onDarkMatches);

//     return () => {
//       lightMatch.removeEventListener("change", onLightMatches);
//       darkMatch.removeEventListener("change", onDarkMatches);
//     };
//   }, []);

//   useLayoutEffect(() => {
//     // SSR or matchMedia not supported
//     if (typeof window === "undefined" || !window.matchMedia) {
//       return;
//     }

//     if (window.matchMedia(colorSchemes.dark).matches && theme !== "dark") {
//       setTheme(SCHEMES.DARK);
//     } else if (
//       window.matchMedia(colorSchemes.light).matches &&
//       theme !== SCHEMES.LIGHT
//     ) {
//       setTheme(SCHEMES.LIGHT);
//     }
//   }, [theme]);

//   return theme;
// }
