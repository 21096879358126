import { toHex } from "color2k";
import { tailwindcssPaletteGenerator } from "@bobthered/tailwindcss-palette-generator";
import {
  CustomizableThemeColorName,
  defaultCustomizableThemeColors,
} from "./themes/app-theme";
import { toHsla } from "color2k";

export type Theme = Record<string, string>;
export type Palette = Record<string, string | Record<string, string>>;
type ShadePalette = Record<string, Record<string, string>>;

/**
 * Takes a project theme and generates a Tailwind palette from it, adding shades
 * to colors that are specified in the `colorsToShade` array.
 */
export function generateZiPalette(
  theme: Theme,
  colorNamesToShade: CustomizableThemeColorName[] = [
    "primary",
    "danger",
    "pageBackground",
  ]
): Palette {
  // Split theme into colors that need shades and colors that don't.
  const colorsToShade: typeof theme = {};
  const colorsWithoutShades: typeof theme = {};
  Object.entries(theme).forEach(([name, color]) => {
    if (colorNamesToShade.includes(name as CustomizableThemeColorName)) {
      colorsToShade[name] = color;
    } else {
      colorsWithoutShades[name] = color;
    }
  });

  // Generate a palette with shades for the colors that need them.
  const paletteWithShades = generatePaletteWithShades(colorsToShade);

  // Add a DEFAULT key to each palette of shades with the original color value.
  // This is needed for Tailwind to work.
  // (see https://tailwindcss.com/docs/customizing-colors#color-object-syntax)
  for (const [name, shades] of Object.entries(paletteWithShades)) {
    paletteWithShades[name] = {
      DEFAULT: theme[name],
      ...shades,
    };
  }

  // Merge the two palettes.
  return {
    ...colorsWithoutShades,
    ...paletteWithShades,
  };
}

/**
 * Generates a Tailwind config object with our default theme colors. Intended
 * for use in `tailwind.config.js`.
 */
export function generateTailwindConfig(theme: Theme): Palette {
  const palette = generateZiPalette(theme);

  const tailwindConfig: Palette = {};

  for (const [name, colorOrShades] of Object.entries(palette)) {
    if (typeof colorOrShades === "string") {
      tailwindConfig[name] = `hsl(var(${getCSSCustomPropertyName(name)}))`;
    } else {
      const colorObj: Record<string, string> = {};
      for (const [shadeName] of Object.entries(colorOrShades)) {
        colorObj[shadeName] = `hsl(var(${getCSSCustomPropertyName(
          name,
          shadeName
        )}))`;
        tailwindConfig[name] = colorObj;
      }
    }
  }

  return tailwindConfig;
}

export function generateDefaultTailwindConfig(): Palette {
  return generateTailwindConfig(defaultCustomizableThemeColors);
}

// Generate CSS custom properties for a palette.
export function generateCSSCustomProperties(palette: Palette): string {
  const flatPalette = getFlatPalette(palette);

  let cssProperties = "";

  for (const [name, color] of Object.entries(flatPalette)) {
    cssProperties += `\n${getCSSCustomPropertyName(name)}: ${toTwHsl(color)};`;
  }

  return cssProperties;
}

// Utilities
// -----------------------------------------------------------------------------

export function getFlatPalette(palette: Palette): Record<string, string> {
  const flatPalette: Record<string, string> = {};
  for (const [name, colorOrShades] of Object.entries(palette)) {
    if (typeof colorOrShades === "string") {
      flatPalette[name] = colorOrShades;
    } else {
      for (const [shadeName, color] of Object.entries(colorOrShades)) {
        if (shadeName === "DEFAULT") {
          flatPalette[name] = color;
        } else {
          flatPalette[`${name}-${shadeName}`] = color;
        }
      }
    }
  }
  return flatPalette;
}

// Generate a palette with shades.
function generatePaletteWithShades(theme: Theme): ShadePalette {
  // Transform project theme into the format expected by the palette generator.
  const arg = Object.entries(theme).reduce<{
    colors: string[];
    names: string[];
  }>(
    (accum, [name, color]) => {
      accum.colors.push(toHex(color));
      accum.names.push(name);
      return accum;
    },
    { colors: [], names: [] }
  );

  // Generate the palette.
  return tailwindcssPaletteGenerator(arg);
}

// Convert a color name from a Palette object to a CSS custom property name.
export function getCSSCustomPropertyName(...segments: string[]): string {
  let name = "--zi";
  for (const segment of segments) {
    if (segment !== "DEFAULT") {
      name += `-${segment}`;
    }
  }
  return name;
}

/** Converts a HEX color into HSL channels in a format Tailwind can use */
export function toTwHsl(color: string) {
  return toHsla(color).replace(
    /hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*([\d\.]+).+$/g,
    "$1 $2% $3% / $4"
  );
}
